<template>
  <section id="detail-kegiatan">
    <b-row>
      <b-col
        lg="3"
        cols="12"
      >
        <kegiatan-about :detail-program="dataProgram" />
        <kegiatan-catatan :detail-program="dataProgram" />
      </b-col>

      <b-col
        lg="9"
        cols="12"
      >
        <kegiatan-detail
          v-if="dataProgram.tahun_anggaran === 2023"
          set-step="rekomendasi"
        />
        <template v-if="dataProgram.tahun_anggaran > 2023">
          <detail-infrastruktur
            v-if="dataProgram.jenis_pengadaan_id == '2'"
            set-step="rekomendasi"
          />
          <detail-aplikasi
            v-if="dataProgram.jenis_pengadaan_id == '3'"
            set-step="rekomendasi"
          />
          <detail-data
            v-if="dataProgram.jenis_pengadaan_id == '6'"
            set-step="rekomendasi"
          />
          <detail-non
            v-if="dataProgram.jenis_pengadaan_id == '7'"
            set-step="rekomendasi"
          />
        </template>
      </b-col>

    </b-row>
  </section>
</template>

<script>
import {
  BRow, BCol,
} from 'bootstrap-vue'
import { getUserData } from '@/auth/utils'

import KegiatanAbout from '../kegiatan/detail-kegiatan/KegiatanAbout.vue'
import KegiatanCatatan from '../kegiatan/detail-kegiatan/KegiatanCatatan.vue'
import KegiatanDetail from '../kegiatan/detail-kegiatan/KegiatanDetail.vue'

import DetailInfrastruktur from '../kegiatan/detail/DetailKegiatanInfrastruktur.vue'
import DetailAplikasi from '../kegiatan/detail/DetailKegiatanAplikasi.vue'
import DetailData from '../kegiatan/detail/DetailKegiatanData.vue'
import DetailNon from '../kegiatan/detail/DetailKegiatanNon.vue'

export default {
  components: {
    BRow,
    BCol,

    KegiatanAbout,
    KegiatanCatatan,
    KegiatanDetail,

    DetailInfrastruktur,
    DetailAplikasi,
    DetailData,
    DetailNon,
  },
  data() {
    return {
      userData: getUserData(),
      dataProgram: {
        utama: {},
        program: {},
        dokumen: {},
        data_info: {},
        layanan: {},
        aplikasi: {},
        infra: {},
        keamanan: {},
      },
    }
  },
  beforeRouteEnter(to, from, next) {
    const role = Number(localStorage.getItem('roleId'))
    if (role > 10 && role < 69 && role > 80) {
      next('/')
    } else {
      next()
    }
    if (!to.params.id) {
      next('/rekomendasi-awal')
    } else {
      next()
    }
  },
  created() {
    this.$http.get('/clearance/core-data', {
      params: {
        token: localStorage.getItem('userToken'),
        data_utama_id: this.$route.params.id,
      },
    })
      .then(res => {
        if (res.data.status === 'success') {
          this.dataProgram = res.data.data

          if (this.userData.group_id > 99 && this.userData.instansi_id !== this.dataProgram.instansi_id) {
            this.$router.replace({ name: 'pengajuan' })
          }
        }
      })
  },
}
</script>
